import React from 'react'
import classnames from 'classnames'
import styles from './index.module.css'

const Container = ({ children, rootStyle }) => {
  return (
    <div className={classnames(styles.Container, rootStyle)}>{children}</div>
  )
}

export default Container
