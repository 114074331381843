import React from 'react'
import { Link } from 'gatsby'
import logo from './logoIFSblackV2.png'
import classnames from 'classnames'
import styles from './index.module.css'
import Container from '../Container'

import Newsletter from '../Newsletter'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Link to="/">
        {/* TODO create a logo component */}
        <img className={styles.logo} src={logo} alt="brand" />
      </Link>

      <Container rootStyle={styles.container}>
        <address className={styles.address}>
          France HQ
          <br />
          12 rue de Turgot
          <br />
          75009 Paris, France
        </address>

        <address className={styles.address}>
          US Office 845 3rd Avenue, <br /> 6th Floor, NY <br /> NY 10022 USA
        </address>

        <address className={styles.address}>
          APAC Office: 18 Robinson Road,
          <br />
          Level 02-03,
          <br />
          Singapore 048547
        </address>
      </Container>

      <div className={styles.socialNetworks}>
        <a href="mailto:info@ifeelsmart.com" className={styles.socialNetwork}>
          <i className={classnames(styles.fa, styles.mail)}></i>
        </a>

        <a
          href="https://fr.linkedin.com/company/ifeelsmart"
          className={styles.socialNetwork}
        >
          <i className={classnames(styles.fa, styles.first)}></i>
        </a>

        <a
          href="https://twitter.com/ifeelsmart"
          className={styles.socialNetwork}
        >
          <i className={classnames(styles.fa, styles.second)}></i>
        </a>

        <a
          href="https://www.facebook.com/iFeelSmartTV"
          className={styles.socialNetwork}
        >
          <i className={classnames(styles.fa, styles.third)}></i>
        </a>
      </div>

      <Newsletter />

      <p className={styles.copyright}>
        &copy; iFeelSmart - 2018/{new Date().getFullYear()}
      </p>
      <p className={styles.trademarks}>
        Android, Google Play, YouTube and Android TV are trademarks of Google
        LLC. Amazon, Fire, Prime Video, Twitch and all related logos are
        trademarks of Amazon.com, Inc. or its affiliates. iPhone, iPad and Apple
        TV are registered trademarks of Apple Inc. Disney+ is registred
        trademark of Disney. HBO Max is registred trademark of WarnerMedia.
        Globoplay is registred trademark of Grupo Globo. Netflix is registred
        trademark.
      </p>
    </footer>
  )
}

export default Footer
