import React from 'react'

import styles from './index.module.css'
import headerImg from './assets/header.jpg'

import addToMailchimp from 'gatsby-plugin-mailchimp'
import Modal, { closeStyle } from 'simple-react-modal'

import { withAlert } from 'react-alert'

class Newsletter extends React.Component {
  state = {
    toggleNewsletter: false,
    adbMsg: false,
    isLoading: false,
  }

  resetValue = () => {
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
      companyName: '',
      msg: '',
      result: '',
      adbMsg: false,
      isLoading: false,
    })
  }

  showMailingPopUp = () => {
    this.resetValue()
    this.setState({
      toggleNewsletter: !this.state.toggleNewsletter,
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    this.setState({
      isLoading: true,
      adbMsg: false,
    })
    e.preventDefault()
    addToMailchimp(this.state.email, {
      FNAME: this.state.firstName,
      LNAME: this.state.lastName,
      // company name test:
      // MMERGE3: this.state.companyName
      // company name IFS:
      CNAME: this.state.companyName,
    })
      .then(data => {
        this.resetValue()
        this.setState({
          msg: data.msg,
          result: data.result,
          toggleNewsletter: false,
        })
        this.displayMsg()
      })
      .catch(() => {
        this.setState({
          adbMsg: true,
          isLoading: false,
        })
      })
  }

  displayMsg = () => {
    if (this.state.result !== '') {
      if (this.state.result === 'success') {
        this.props.alert.success(this.state.msg)
      } else if (this.state.msg.match(/is already subscribed/i)) {
        this.props.alert.error('you have already subscribed')
      } else {
        this.props.alert.error('The email you entered is not valid.')
      }
    }
    this.resetValue()
  }

  render() {
    return (
      <div>
        <button
          className={styles.newsletterBtn}
          onClick={this.showMailingPopUp}
        >
          newsletter
        </button>
        <Modal
          containerStyle={{
            maxWidth: 490,
            width: '90%',
            padding: 0,
            margin: '25px auto',
          }}
          containerClassName={styles.modal}
          closeOnOuterClick={true}
          show={this.state.toggleNewsletter}
          onClose={this.showMailingPopUp}
        >
          <span style={closeStyle} onClick={this.showMailingPopUp}>
            X
          </span>

          <div className={styles.Newsletter}>
            {/* TODO: fix the img height or use background */}
            <div className={styles.header}>
              <img src={headerImg} alt="Newsletter header" />
            </div>
            <form onSubmit={this.handleSubmit}>
              <h3>Subscribe to our mailing list</h3>
              <p>
                <label>Email Address</label>
                <input
                  value={this.state.email}
                  type="text"
                  name="email"
                  onChange={this.handleChange}
                  required
                />
              </p>
              <p>
                <label>First Name</label>
                <input
                  value={this.state.firstName}
                  type="text"
                  name="firstName"
                  onChange={this.handleChange}
                  required
                />
              </p>
              <p>
                <label>Last Name</label>
                <input
                  value={this.state.lastName}
                  type="text"
                  name="lastName"
                  onChange={this.handleChange}
                  required
                />
              </p>
              <p>
                <label>Company Name</label>
                <input
                  value={this.state.companyName}
                  type="text"
                  name="companyName"
                  onChange={this.handleChange}
                  required
                />
              </p>
              {this.state.adbMsg && (
                <p className={styles.infoMsg}>
                  Please disable your ad blocker during registration
                </p>
              )}
              <div className={styles.newsletterBtnContainer}>
                <button className={styles.newsletterBtn}>
                  {this.state.isLoading ? 'Loading...' : 'Subscribe'}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withAlert()(Newsletter)
