import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import Footer from '../Footer'
import Header from '../Header'
import favicon from '../../../static/favicon.ico'
import './index.css'
import './fonts.css'

// TODO: add gatsby-images

export default ({ children }) => {
  const options = {
    timeout: 5000,
    position: 'top right',
    containerStyle: {
      marginTop: 60,
    },
  }
  return (
    <div style={{ position: 'relative' }}>
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                lang
                title
                description
                keywords
              }
            }
          }
        `}
        render={data => (
          <Helmet
            htmlAttributes={{ lang: data.site.siteMetadata.lang }}
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
              {
                name: 'keywords',
                content: data.site.siteMetadata.keywords,
              },
            ]}
            link={[
              {
                rel: 'shortcut icon',
                type: 'icon',
                href: `${favicon}`,
              },
            ]}
          />
        )}
      />
      <AlertProvider template={AlertTemplate} {...options}>
        <Header />
        <div
          style={{
            margin: '0 auto',
            paddingTop: 60,
            overflowX: 'hidden',
          }}
        >
          {children}
        </div>
        <Footer />
      </AlertProvider>
    </div>
  )
}
