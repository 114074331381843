import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { slide as Menu } from 'react-burger-menu'
import MediaQuery from 'react-responsive'

import logo from './logoIFSblackV2.png'
import styles from './index.module.css'

class Header extends React.Component {
  render() {
    // TODO remove outline
    const stylesBurger = {
      bmBurgerButton: {
        position: 'fixed',
        width: '30px',
        height: '30px',
        right: '50px',
        top: '14px',
        transform: 'scale(.8, .6)',
      },
      bmMenuWrap: {
        top: 0,
      },
      bmBurgerBars: {
        background: '#4a4a4a',
      },
      bmCrossButton: {
        height: '22px',
        width: '22px',
        right: '53px',
        top: '18px',
      },
      bmCross: {
        // background: '#4a4a4a',
      },
      bmMenu: {
        background: '#fff',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
      },
      bmMorphShape: {
        fill: '#373a47',
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
        left: 0,
        top: '60px',
      },
    }
    const active = {
      color: 'rgba(187,25,51,.84)',
      borderBottom: '3px solid rgba(187,25,51,.84)',
      paddingBottom: '4px',
    }
    const activeBurger = {
      color: 'rgba(187,25,51,.84)',
    }
    return (
      <header className={styles.header}>
        <Link to="/" className={styles.brand}>
          {/* TODO create a logo component */}
          <img className={styles.logo} src={logo} alt="iFeelsmart" />
        </Link>

        <MediaQuery query="(min-width: 993px)">
          <nav className={styles.nav}>
            <Link
              activeStyle={active}
              innerRef={el => {
                this.myLink = el
              }}
              className={styles.navlink}
              to="/"
            >
              Home
            </Link>
            <Link
              activeStyle={active}
              innerRef={el => {
                this.myLink = el
              }}
              className={styles.navlink}
              to="/solutions/"
            >
              Solutions
            </Link>
            <Link
              activeStyle={active}
              innerRef={el => {
                this.myLink = el
              }}
              className={styles.navlink}
              to="/products/"
            >
              Products
            </Link>
            <Link
              activeStyle={active}
              innerRef={el => {
                this.myLink = el
              }}
              className={styles.navlink}
              to="/references/"
            >
              References
            </Link>
            <Link
              activeStyle={active}
              innerRef={el => {
                this.myLink = el
              }}
              className={styles.navlink}
              to="/about/"
            >
              About us
            </Link>
            {/* <Link
              activeStyle={active}
              innerRef={(el) => { this.myLink = el }} className={styles.navlink} to="/careers"
            >
              Careers
            </Link> */}
          </nav>
        </MediaQuery>

        <MediaQuery query="(max-width: 992px)">
          <Menu
            styles={stylesBurger}
            className={styles.burgerIcon}
            right
            customCrossIcon={
              <img
                src="https://cdn.onlinewebfonts.com/svg/img_391885.png"
                alt="cross icon"
              />
            }
          >
            <Link
              className={classnames(styles.navlink, styles.burgerLink)}
              activeStyle={activeBurger}
              to="/"
            >
              Home
            </Link>
            <Link
              className={classnames(styles.navlink, styles.burgerLink)}
              activeStyle={activeBurger}
              to="/solutions/"
            >
              Solutions
            </Link>
            <Link
              className={classnames(styles.navlink, styles.burgerLink)}
              activeStyle={activeBurger}
              to="/products/"
            >
              Products
            </Link>
            <Link
              className={classnames(styles.navlink, styles.burgerLink)}
              activeStyle={activeBurger}
              to="/references/"
            >
              References
            </Link>
            <Link
              className={classnames(styles.navlink, styles.burgerLink)}
              activeStyle={activeBurger}
              to="/about/"
            >
              About us
            </Link>
            {/* <a className={classnames(styles.navlink, styles.burgerLink)} href="/">Careers</a> */}
          </Menu>
        </MediaQuery>
      </header>
    )
  }
}

export default Header
